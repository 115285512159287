import React from 'react';
import { SPOT_REVIEWS_SORT_CONTENT } from '../../consts/spot.consts';
import SvgCheck from 'src/assets/svg/SvgCheck.svg';
import SvgChevronDown from 'src/assets/svg/SvgChevronDown.svg';
import SvgSort from 'src/assets/svg/SvgSort.svg';
import './sort-reviews.scss';

const SortReviews = ({ toggleSortOpen, sortOpen, onChangeSort, sortReviews }) => {
    return (
        <div className="sort-reviews" onClick={toggleSortOpen}>
            <SvgSort />
            <span className="snif-p">{sortReviews.text}</span>
            <SvgChevronDown className="chevron-sort" />
            {sortOpen && (
                <div className="sort-reviews-modal snif-p">
                    {Object.keys(SPOT_REVIEWS_SORT_CONTENT).map((v) => {
                        const picked = v === sortReviews.id;
                        return (
                            <div key={v} onClick={() => onChangeSort(v)} className={picked ? 'picked' : ''}>
                                {SPOT_REVIEWS_SORT_CONTENT[v].text}
                                {picked && <SvgCheck />}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default SortReviews;
